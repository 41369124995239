import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { ACCOUNTING, TAXPAYERS, REFERENCE_CODE, INVOICES, PAYROLL, BILLING, CRM_SALES, ADMIN, ADMIN_SALES, ADMIN_REPORTS, ACCOUNT_REPORTS, ADMIN_ADMINISTRATORS, METRIC, VIEW_CONFIG_CATALOGS, ACCOUNT_PREREPORTSUPGRADE, ACCOUNT_DOWNLOADXML, ACCOUNT_ADITIONALPROCEDURES, CRM_DIAGNOSTIC, CRM_PAYMENTVALIDATE } from '../constants/Routes';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { startLogout } from '../../actions/auth';
import { ModalContext } from '../modal/ModalContext';
import { GenerarToken } from '../pages/crm/sales/PreSale/GenerarToken';
import { ModuleHelpers } from './ModuleHelpers';
import './Style.scss';

export const NavbarCustomer = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { handleModal } = useContext(ModalContext);
  const { descriptionRole } = user;
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(startLogout());
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerWidth = 250;

  const drawerContent = (


    <List>
      <div className="col-12 text-left">
        <img height="50" src='img_logoNew.png' />
      </div>
      {/* {(descriptionRole === 'Seller') && (
        <Typography variant="h6" className="navbar-title" >
          Vendedor
        </Typography>
      )}
      {(descriptionRole === 'Administration') && (
        <Typography variant="h6" className="navbar-title">
          Administrador
        </Typography>
      )}
      {(descriptionRole === 'Service Customer') && (
        <Typography variant="h6" className="navbar-title">
          Servicio al cliente
        </Typography>
      )}
      {(descriptionRole === 'Accounter') && (
        <Typography variant="h6" className="navbar-title" style={{ textAlign: 'center' }}>
          Contador
        </Typography>
      )} */}

      <Divider style={{ backgroundColor: '#ffffff', margin: '10px 0' }} />

      {(descriptionRole === 'Administration' || descriptionRole === 'Service Customer') && (
        <ListItem
          button
          component={Link}
          to={ADMIN}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-user-secret"></i></ListItemIcon>
          <ListItemText primary="Administración" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {(descriptionRole === 'Seller' || descriptionRole === 'Service Customer') && (
        <ListItem
          button
          component={Link}
          to={CRM_SALES}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-shopping-cart"></i></ListItemIcon>
          <ListItemText primary="Ventas" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Accounter') || (descriptionRole === 'Administration' || descriptionRole === 'Service Customer')) && (
        <ListItem
          button
          component={Link}
          to={ACCOUNT_REPORTS}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280', marginRight: '500px' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-file-signature"></i></ListItemIcon>
          <ListItemText primary="Pre reportes v1" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff', } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Accounter') || (descriptionRole === 'Administration' || descriptionRole === 'Service Customer')) && (
        <ListItem
          button
          component={Link}
          to={ACCOUNT_PREREPORTSUPGRADE}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-file-signature"></i></ListItemIcon>
          <ListItemText primary="Pre reportes v2" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Accounter') || (descriptionRole === 'Administration' || descriptionRole === 'Service Customer')) && (
        <ListItem
          button
          component={Link}
          to={ACCOUNT_DOWNLOADXML}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-file-signature"></i></ListItemIcon>
          <ListItemText primary="Descarga de xml" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Seller' || descriptionRole === 'Service Customer' || descriptionRole === 'Accounter' || descriptionRole === 'Administration')) && (
        <ListItem
          button
          component={Link}
          to={ACCOUNT_ADITIONALPROCEDURES}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-file-signature"></i></ListItemIcon>
          <ListItemText primary="Tramites adicionales" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      <ListItem
        button
        component={Link}
        to={ADMIN_ADMINISTRATORS}
        onClick={toggleDrawer(false)}
        style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
      >
        <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-user-secret"></i></ListItemIcon>
        <ListItemText primary="Usuarios" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
      </ListItem>

      {((descriptionRole === 'Accounter' || descriptionRole === 'Service Customer')) && (
        <ListItem
          button
          component={Link}
          to={CRM_DIAGNOSTIC}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-user-secret"></i></ListItemIcon>
          <ListItemText primary="Diagnostico" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Accounter')) && (
        <ListItem
          button
          component={Link}
          to={CRM_PAYMENTVALIDATE}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-user-secret"></i></ListItemIcon>
          <ListItemText primary="Validación de pagos" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Accounter')) && (
        <ListItem
          button
          component={Link}
          to={VIEW_CONFIG_CATALOGS}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-file-signature"></i></ListItemIcon>
          <ListItemText primary="Configuración de catalogos" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {/* {((descriptionRole === 'Seller')) && (
        <ListItem
          button
          component={Link}
          to={CRM_RENOVATIONS}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-cart-arrow-down"></i></ListItemIcon>
          <ListItemText primary="Renovaciones" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )} */}

      {((descriptionRole === 'Guest')) && (
        <ListItem
          button
          component={Link}
          to={ACCOUNTING(null, null, null)}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-coins"></i></ListItemIcon>
          <ListItemText primary="Contabilidad" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Guest')) && (
        <ListItem
          button
          component={Link}
          to={TAXPAYERS}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-users"></i></ListItemIcon>
          <ListItemText primary="Contribuyentes" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Guest')) && (
        <ListItem
          button
          component={Link}
          to={INVOICES}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-file-import"></i></ListItemIcon>
          <ListItemText primary="Facturas" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Guest')) && (
        <ListItem
          button
          component={Link}
          to={PAYROLL}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-file-invoice-dollar"></i></ListItemIcon>
          <ListItemText primary="Recibos de nomina" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Guest')) && (
        <ListItem
          button
          component={Link}
          to={BILLING}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-file-invoice-dollar"></i></ListItemIcon>
          <ListItemText primary="Facturacion" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Guest')) && (
        <ListItem
          button
          component={Link}
          to={REFERENCE_CODE}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-file-invoice-dollar"></i></ListItemIcon>
          <ListItemText primary="Código de Referencia" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}

      {((descriptionRole === 'Service Customer')) && (
        <ListItem
          button
          component={Link}
          to={METRIC}
          onClick={toggleDrawer(false)}
          style={{ borderRadius: '8px', marginBottom: '10px', backgroundColor: '#036280' }}
        >
          <ListItemIcon style={{ color: '#ffffff' }}><i className="fas fa-chart-line"></i></ListItemIcon>
          <ListItemText primary="Metricas mobile" primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#ffffff' } }} />
        </ListItem>
      )}
    </List>
  );

  return (
    <div style={{ display: 'flex', marginBottom: '20px' }}>
      <AppBar position="sticky" className="navbar-custom" style={{ backgroundColor: '#036280' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={toggleDrawer(true)} style={{ marginRight: '16px' }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className="navbar-title" style={{ flexGrow: 1 }}>
            Bienvenido {user.userName}
          </Typography>
          <div className="navbar-links">
            <Button color="inherit" onClick={() => handleModal(<ModuleHelpers />)} style={{ marginRight: '10px', padding: '6px 12px' }}>
              <i className="far fa-folder-open"></i> Manuales de usuario
            </Button>
            {user.isBoss && (
              <Button color="inherit" onClick={() => handleModal(<GenerarToken />)} style={{ marginRight: '10px', padding: '6px 12px' }}>
                <i className="fas fa-key"></i> Generar Token
              </Button>
            )}
            <Button color="inherit" onClick={handleLogout} style={{ padding: '6px 12px' }}>
              <i className="fas fa-sign-out-alt"></i> Cerrar sesión
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            backgroundColor: '#033540',
            color: '#fff',
            width: drawerWidth,
            borderRadius: '0 16px 16px 0',
            marginTop: '62px',
            paddingTop: '10px'
          }
        }}
      >
        {drawerContent}
      </Drawer>
    </div>
  );
};